.App {
  text-align: center;
}

.App-logo {
  -webkit-filter: drop-shadow(1px 1px 0px #fff);
          filter: drop-shadow(1px 1px 0px #fff);
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Insigna';
  font-style: normal;
  font-weight: 400;
  src: local('Insigna'), url(/static/media/Insignia.d7df8e95.otf) format('woff');
}

body {
  font-family: Roboto;
}

